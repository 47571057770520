import React, { useEffect, useState } from 'react';
import { Routes, Route, useParams, useLocation, useNavigate } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Recipe } from '../../models';
import './RecipeView.css';


const RecipeView = (props)=>{
    const location = useLocation();
    const [id,setId] = useState(props.id);
    //const [recipeID,setRecipeID] = useState(props.recipeItem.id);
    //const [name,setName] = useState(props.recipeItem.name);
    //const [image,setImage] = useState(props.recipeItem.image);
    const navigate = useNavigate();
    const [item, setItem] = useState(props.recipeItem);

    useEffect(()=>{
        document.getElementById('title').scrollIntoView({behavior:'smooth'});
    },[id]);

    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }
    const images = importAll(require.context('../../img', false, /\.(png|jpe?g|svg)$/));
    //console.log('reciepeView',id);
    
    
    const highlightHandler = (event)=>{
        let target = event.target;
        let node = document.getElementById(event.target.id);
        if(!node.classList.contains('ingredient-gradient')){
            node.style.color="white";
            node.classList.add('ingredient-gradient');
        }else{
            node.style.color="black";
            node.classList.remove('ingredient-gradient');
        }
        
        
    };
    
    
    let count=0;
    let ingredients = item.ingredients.map(ingredient => {
        count+=1;
        return (<li className='list-item-ingredient' id={count} onClick={highlightHandler} data-clicked={0}>{ingredient}</li>)
    });
    //let instructions = item.instructions.map((instruction)=>{
    //    return(<p className='fixed'>{instruction}</p>)
    //});
    let imgurl = images[`${item.image}`];
    let instructionsProcessed='';
    let backbtnimg = images[`back.png`]
    let iteminstructions = item.instructions.replaceAll('\\n','\n')
    for (let index = 0; index < iteminstructions.length; index++) {
        const character = iteminstructions[index];
        if(character==='\n'){
            instructionsProcessed=instructionsProcessed+'\n';
        }
        instructionsProcessed = instructionsProcessed+character
        
    }
    
    //console.log('vidurl',item.videourl);
    //<img onClick={()=>navigate(`/`)} className='back-button-image' src={backbtnimg}></img>
    return(
        <div className="recipe-view">
            <header className='recipe-title' id='title'>
                <div className='back-button-container'>
                    
                    <img onClick={()=>navigate(`/`)} className='back-button-image' src={backbtnimg}></img>
                </div>
                {item.name}
            </header>
            <img className="recipe-view-image" src={imgurl}/>
            <hr className="dotted"></hr>
            <div className='recipe-header'>
                Ingredients
            </div>
            <ul role="list" className='recipe-ingredients noselect'>{ingredients}</ul>
            <hr className="dotted"></hr>
            <div className='recipe-header'>
                Instructions
            </div>
            <div className='recipe-instructions'>{instructionsProcessed}</div>
            <hr className="dotted"></hr>
            <header className='recipe-header'>Video</header>
            <div className='video'>
            <iframe  
                src={item.videourl}
                width="100%"
                height="100%"
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen>

            </iframe>
  </div>
            
        </div>
    )
};

export default RecipeView;


/**
 * 
  <iframe 
     width="1711" 
     height="693" 
     src="https://www.youtube.com/embed/3nBKxv_If6M" 
     title="Cooking Buttery Bread-Machine Croissant Rolls, Ono Hawaii-style Kalbi and Hawaii Mac Salad" 
     frameborder="0" 
     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
     allowfullscreen>
  </iframe>
 * 
 * 
 * 
 * 
 * useEffect(()=>{
        
        const fetchData= async ()=>{  
          
            let dataobj = {};
            let ids = [];
            console.log('idididididi',id);
            const recipe = await DataStore.query(Recipe,id);
            console.log('dbquery',recipe)
            setItem(item);      
        };
        
        fetchData();
    },[props]);
    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }
    const images = importAll(require.context('../../img', false, /\.(png|jpe?g|svg)$/));
 * 
 * 
 */
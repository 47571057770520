/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "MMMRest",
            "endpoint": "https://gwb164mfxf.execute-api.us-west-1.amazonaws.com/staging",
            "region": "us-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://sfiaeypm3bb4nlemr2fdt5mi6e.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-drv4rcln5ffshlceas7o6mmv2y",
    "aws_dynamodb_all_tables_region": "us-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Recipe-v43ysl2vfzfy5c27hxs535z5mq-staging",
            "region": "us-west-1"
        }
    ]
};


export default awsmobile;

import { Routes, Route, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Recipe } from '../../models';
import { Amplify, API } from 'aws-amplify';
import awsconfig from "../../aws-exports";
import RecipeView from "./RecipeView";
import './RecipeView.css';




const RecipeURL = (props) => {
    const location = useLocation();
    const [recipeItem, setRecipeItem] = useState('');
    const [recipeId, setRecipeId] = useState('');
    const { id } = useParams();
    const [loading,setLoading] = useState(true);
    
    useEffect(()=>{
        
        const fetchData= async ()=>{  
            
            const item = await DataStore.query(Recipe,id);
            setRecipeItem(item);
            setLoading(false);
        };
    
        fetchData();
    },[id]);
    
    
    if(loading){
        return (
            <div>

                <h2>Recipe:{id} LOADING...</h2>
                
            </div>
        );
    }
    
    if(recipeItem){
        return(
            <div>
                <RecipeView key={recipeItem.id} id={recipeItem.id} recipeItem={recipeItem}/>
            </div>
        );
    }else{
        return (
            <div>
  
                <h2>Recipe ID: {id} Not Found</h2>
                
            </div>
        );
    }
    
};
//
export default RecipeURL;

import logo from './img/cooking_logo4.png';
import './App.css';
import headerBackground from './img/wallpaper.png';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import RecipeInput from './components/RecipeInput/RecipeInput';
import RecipePagination from './components/RecipeList/RecipePagination';
import React, { useEffect, useState } from 'react';
import RecipeItem from "./components/RecipeItem/RecipeItem";
import RecipeView from "./components/RecipeView/RecipeView";
import RecipeURL from "./components/RecipeView/RecipeURL";
import recipeDB from './data/db.json';
import { DataStore } from '@aws-amplify/datastore';
import { Recipe } from './models';
import { Amplify, API } from 'aws-amplify';
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);
DataStore.configure(awsconfig);

const RESTapi = `MMMRest`;
const path = `/recipes`;


function App() {

  DataStore.start()
  const [clickedrecipe, setClickedRecipe] = useState('');
  
  
  


  function importAll(r) {
    let images = {};
     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
   }
  const images = importAll(require.context('./img', false, /\.(png|jpe?g|svg)$/));
  
  //const[db,setDB] = useState(recipeDB)
  const[recipes,setRecipes] = useState([]);
  const[recipeList,setRecipeList] = useState({});//recipeDB['recipes']
  const[searchResults,setSearchResults] = useState([]);
  const[searchTerm,setSearchTerm] = useState('');
  const[recipeItem,setRecipeItem] = useState('');
  const[recipeIndices,setRecipeIndices] = useState([]);//recipeDB['recent']
  const[currentPageNumber,setCurrentPageNumber] = useState(1);
  const[pagination,setPagination] = useState([]);
  const[categorySearch, setCategorySearch] = useState([]);
  const[category,setCategory] = useState('');
  const[previousCategory, setPreviousCategory] = useState('');
  const[previousCategorySearch, setPreviousCategorySearch] = useState([]);
  const[previousSearchTerm,setPreviousSearchTerm] = useState('');
  const[previousSearchResults, setPreviousSearchResults] = useState([]);
  const[getURL, setGetURL] = useState([]);




  //const[jsxoutput,setjsxoutput] = useState(homepage);
  useEffect(()=>{
    DataStore.start();//new line
    const fetchData= async ()=>{  
      if(recipeIndices.length===0){
        let dataobj = {};
        let ids = [];
        const models = await DataStore.query(Recipe);


        models.forEach((recipe,idx)=>{
          
          dataobj[recipe.id] = {
            'id':recipe.id,
            'name':recipe.name,
            'image':recipe.image,
            'category':recipe.category,
            'ingredients':recipe.ingredients,
            'instructions':recipe.instructions,
            'videourl':recipe.videourl
          };
          ids.push(recipe.id);
        });
        
        setRecipeList(dataobj)
        
        setRecipeIndices(ids)
      }
      
    };
    
    fetchData();
  }, [,recipeIndices]);


  //Function to fetch from our backend and update customers array
  useEffect(()=>{
    const getClickedRecipe = async ()=>{

      const recipeREST = await API.get(RESTapi, path+ "/" + recipeItem.id 
      //{
      //  queryStringParameters: {
      //    recipeId: recipeItem.id
      //  }
      //}
      )
      .catch(error => {
        console.log(error)
      });
      
      
  
    }
    if(recipeItem.id){
      
      getClickedRecipe();
    }

  },[recipeItem]);
  
  


  const recieveUserRecipe = (userrecipe)=>{
    
    let recipeobj = {id:userrecipe,recipe:userrecipe}
    let rlist= recipeList
    setRecipeList(prevState =>{
      return [...prevState,recipeobj]
    })

  };

  const searchRecipeDB = (searchterm)=>{
    setRecipeItem('');

    let searchedRecipes = []    
    
    recipes.forEach(recipe=>{
      if(recipe.name.toLowerCase().includes(searchterm.toLowerCase())){
        searchedRecipes.push(recipe);
      }
    });


    let validRecipeIDs=[]
    recipeIndices.forEach(recipeid=>{
    let recipe = recipeList[recipeid];
    
    if(recipe.name.toLowerCase().includes(searchterm.toLowerCase())){
        validRecipeIDs.push(recipeid)
        
    }
    });
    setPreviousCategory((prevState)=>([...prevState,category]));
    setPreviousCategorySearch((prevState)=>([...prevState,categorySearch]));
    setPreviousSearchResults((prevState)=>([...prevState,searchResults]));
    setPreviousSearchTerm((prevState)=>([...prevState,searchTerm]));
    setCategory('');
    setCategorySearch([]);
    setSearchResults((prevState)=>(validRecipeIDs));
    setSearchTerm((prevState)=>(searchterm));
  

  };

  const updateListAfterDeletion = (alteredlist)=>{
    setRecipeList(alteredlist);
  };

  const renderRecipe = (item)=>{
    
    setRecipeItem(recipeList[`${item.id}`]);
    //getClickedRecipe(item);

  };

  const renderCategory = (category)=>{
    

    let searchedRecipes = []    
    
    recipes.forEach(recipe=>{
      recipe.category.forEach(recipeCategory=>{
        if(recipeCategory === category){
          searchedRecipes.push(recipe);
        }
      });
      
    });


    let categoryids = [];
    //grab indices from all recipes with specfied category
    recipeIndices.forEach((id)=>{
      let item = recipeList[id];
      item.category.forEach((cat)=>{
        
        if(category===cat){
          categoryids.push(id);
        }
      });

    });
    setPreviousCategory((prevState)=>([...prevState,category]));
    setPreviousCategorySearch((prevState)=>([...prevState,categorySearch]));
    setPreviousSearchResults((prevState)=>([...prevState,searchResults]));
    setPreviousSearchTerm((prevState)=>([...prevState,searchTerm]));
    setSearchResults((prevState)=>([]));
    setSearchTerm((prevState)=>(''));
    setCategory((prevState)=>(category));
    setCategorySearch((prevState)=>(categoryids));
  };

  const setCurrentPage = (page)=>{
    setCurrentPageNumber(page);
  };
  const reset = (event)=>{
    setRecipeItem('');
    setSearchTerm('');
    setSearchResults([]);
    setCategorySearch([]);
    setCategory('');
  };

  const previousPaginationState = ()=>{
  
    setCategory((prevState)=>(previousCategory));
    setCategorySearch((prevState)=>(previousCategorySearch));
    setSearchResults((prevState)=>(previousSearchResults));
    setSearchTerm((prevState)=>(previousSearchTerm));

  
  };

  const initdb = async()=>{
    
    //<button className='initdb' onClick={initdb}>INIT</button>
    
    
    

    const models = await DataStore.query(Recipe);

    //models.forEach(recipe=>{
    //    DataStore.delete(recipe)
    //  });

    //models.forEach(recipe=>{
    //  console.log(recipe.name)
    //});
  };
  
  let jsxoutput = [];
  

  if(searchResults.length>0){
    
    
    jsxoutput.push(
      <div>
        <div className='centered-content'>
        <RecipeInput recieveUserSearch={searchRecipeDB}/>
        </div>
        <div className='search-message'>
          <div className='search'>{searchTerm} :</div>
          <div className='results'> Found {searchResults.length} results</div>
        </div>
        <div className='reset'>
          <button className="reset-button" onClick={reset}>RESET</button>
        </div>
        <div className="recipe-search">
          <RecipePagination key={searchTerm} recipes={recipes} recipeIndices={searchResults} recipeList={recipeList} renderRecipe={renderRecipe} images={images} setCurrentPage={setCurrentPage} page={currentPageNumber} renderCategory={renderCategory}/>
        </div>
      </div>)
  }else if(categorySearch.length>0){
    
    jsxoutput.push(
      <div>
        <div className='centered-content'>
        <RecipeInput recieveUserSearch={searchRecipeDB}/>
        </div>
        <div className='search-message'>
          <div className='search'>{category} :</div>
          <div className='results'>Found {categorySearch.length} results</div>
        </div>
        <div className='reset'>
          <button className="reset-button" onClick={reset}>RESET</button>
        </div>
        
        <div className="recipe-search">
          
          <RecipePagination key={category} recipes={recipes} recipeIndices={categorySearch} recipeList={recipeList} renderRecipe={renderRecipe} images={images} setCurrentPage={setCurrentPage} page={currentPageNumber} renderCategory={renderCategory}/>
        </div>
    </div>)

  }
  else{
    
    jsxoutput.push(<div>
                    <div className='centered-content'>
                      <RecipeInput key='input' recieveUserSearch={searchRecipeDB}/>
                    </div>
                  
                    <RecipePagination key='no_search' recipes={recipes} recipeIndices={recipeIndices} recipeList={recipeList} renderRecipe={renderRecipe} images={images} setCurrentPage={setCurrentPage} page={currentPageNumber} renderCategory={renderCategory}/>  
                  </div>)
    
    

    //<RecipePagination recipeIndices={searchResults} recipeList={recipeList} renderRecipe={renderRecipe} images={images} setCurrentPage={setCurrentPage} page={currentPageNumber}/>)
    //<RecipeSearch recipeList={recipeList} searchterm={searchTerm} searchResults={searchResults} renderRecipe={renderRecipe} images={images} setCurrentPage={setCurrentPage} page={currentPageNumber}/>)
  }
    
  //navigating to specific recipe page
  //if(recipeItem.id){
    //console.log('the ITEM  ',recipeItem)
    
    //jsxoutput = <RecipeView key={recipeItem.id} id={recipeItem.id} recipeItem={recipeItem} reset={reset}/>; //recipeItem={recipeItem} images={images}
  //}
  



  return (
  <Router>
  
    <div className="App">
      <header className="App-header">
        <p className='main-title'>
          Mr and Mrs Meals
        </p>
        
        <img src={logo} className="App-logo" alt="logo" />
      </header>  
      <div className='content'>


        
      
      
      
        
        
        

        
        <Routes>
        
        <Route key='home' path='/' element={jsxoutput} />
        <Route key='item' path='/recipes/:id' element={<RecipeURL key={recipeItem.id} />} />
        
        </Routes>
          
      
      </div>
      
      
      
    
    </div>
      
  </Router>
    
  );
}

export default App;



//<RecipeList recipeList={recipeList} updateList={updateListAfterDeletion}/>
//<button onClick={previousPaginationState}>previous state</button>
//<Route path='/recipes/:id' element={<RecipeURL/>} />